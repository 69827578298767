import "vendor/w3/accordion"
;(function($) {
  $(document).ready(function() {
    /**
     * Slider Module
     */
    $(".slider-wrapper").slick({
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 3,
      autoplay: true,
      responsive: [
        {
          breakpoint: 900,
          slidesToShow: 1
        }
      ]
    })

    /**
     * Tesitmonial Module
     */
    $(".tes-inner").slick({
      autoplaySpeed: 5000,
      autoplay: true
    })

    /**
     * Hero slider
     */
    $(".hero-slider").slick({
      infinite: true,
      autoplay: true,
      fade: true,
      pauseOnHover: false
    })

    /*
     *	Makes mobile submenus toggleable
     */
    $(".menu-item-has-children").on("click", function() {
      $(this).toggleClass("toggled")
    })

    /*
     *	Adds a visible class for scroll animations
     */
    var boxes = Array.from(document.getElementsByClassName("scroll-show"))

    // Runs the function on scroll
    window.addEventListener("scroll", function(e) {
      visibility()
    })

    function visibility() {
      boxes.forEach(function(elem, p) {
        if (
          elem.getBoundingClientRect().top +
            document.documentElement.scrollTop +
            elem.offsetHeight / 4 <=
          window.innerHeight + document.documentElement.scrollTop
        ) {
          elem.classList.add("visible")
        }
      })
    }
    visibility()

    /**
     * Add sticky nav class
     */
    const $header = $(".header")
    const headerHeight = $header.height()
    const stickyClass = "sticky"
    const $mainContent = $("#main-content")

    // Set maincontent top margin
    const setMainContentMargin = function() {
      $mainContent.css("margin-top", headerHeight + "px")
    }
    setMainContentMargin()
    $(window).on("resize", function() {
      setMainContentMargin()
    })

    $(window).scroll(function() {
      if ($(this).scrollTop() > 0) {
        $header.addClass(stickyClass)
      } else {
        $header.removeClass(stickyClass)
      }
    })
  })
})(jQuery)
